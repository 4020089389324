import React from "react"

const FlatButton = (props) => {
    return (
      <div className="FlatButton">
       <h2> <span className="flat-button">{props.children}</span></h2>
      </div>
    );
  }
  
  export default FlatButton;
  